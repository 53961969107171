import request from '@/utils/request'
import * as _ from "lodash";
import { CommentParams } from "@/classes/exam-comment";

const MaxTimeout = 150000;

/**
 * 准备报表数据
 * @param data 
 * @param disabledTips 
 * @returns 
 */
export const readyExamProjectReportData = (data: CommentParams, disabledTips: boolean = false) => request({
    url: '/v1/examComment/statistics/readyExamProjectReportData',
    method: 'POST',
    data,
    params: {
        disabledTips
    }
});

/**
 * 学生纬度试卷统计
 * @param params 
 * @returns 
 */
export const downloadExamProjectReport = (params: CommentParams) => request({
    url: '/v2/statistics/download/examProjectReport',
    method: 'get',
    headers: {
        "Content-Type": "application/json",
    },
    params: _.merge(params, {
        //disabledTips: true
    })
});


/**
 * 学生纬度试卷统计
 * @param params 
 * @returns 
 */
export const getExamPaperScoreReport = (params: CommentParams) => request({
    url: '/v1/examComment/statistics/examPaperScoreReport',
    method: 'get',
    headers: {
        "Content-Type": "application/json",
    },
    params: _.merge(params, {
        //disabledTips: true
    })
});


/**
 * 学生纬度大题统计(不分试卷)
 * @param params 
 * @returns 
 */
export const getExamPaperSectionScoreReport = (params: CommentParams) => request({
    url: '/v1/examComment/statistics/examPaperSectionScoreReport',
    method: 'get',
    headers: {
        "Content-Type": "application/json",
    },
    params: _.merge(params, {
        //disabledTips: true
    })
});

/**
 * 学生纬度班级大题统计(不分试卷)
 * @param params 
 * @returns 
 */
export const getExamClassPaperSectionScoreReport = (params: CommentParams) => request({
    url: '/v1/examComment/statistics/examClassPaperSectionScoreReport',
    method: 'get',
    headers: {
        "Content-Type": "application/json",
    },
    params: _.merge(params, {
        //disabledTips: true
    })
});



/**
 * 题目纬度小题统计(分试卷)
 * @param params 
 * @returns 
 */
export const getExamPaperQuestionScoreReport = (params: CommentParams) => request({
    url: '/v1/examComment/statistics/examPaperQuestionScoreReport',
    method: 'get',
    headers: {
        "Content-Type": "application/json",
    },
    params: _.merge(params, {
        //disabledTips: true
    })
});

/**
 * 学生纬度成绩分段统计(不分试卷)
 * @param params 
 * @returns 
 */
export const getExamScoreSectionStatisticsReport = (params: CommentParams) => request({
    url: '/v1/examComment/statistics/examScoreSectionStatisticsReport',
    method: 'get',
    headers: {
        "Content-Type": "application/json",
    },
    params: _.merge(params, {
        //disabledTips: true
    })
});

/**
 * 学生纬度大题成绩分段统计(不分试卷)
 * @param params 
 * @returns 
 */
export const getExamPaperSectionScoreSectionStatisticsReport = (params: CommentParams) => request({
    url: '/v1/examComment/statistics/examPaperSectionScoreSectionStatisticsReport',
    method: 'get',
    headers: {
        "Content-Type": "application/json",
    },
    params: _.merge(params, {
        //disabledTips: true
    })
});


/**
 * 学生纬度班级成绩统计(不分试卷)
 * @param params 
 * @returns 
 */
export const getExamClassScoreStatisticsReport = (params: CommentParams) => request({
    url: '/v1/examComment/statistics/examClassScoreStatisticsReport',
    method: 'get',
    headers: {
        "Content-Type": "application/json",
    },
    params: _.merge(params, {
        //disabledTips: true
    })
});

