
















































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import pagination from "@/components/pagination/index.vue";
import * as SchoolApi from "@/api/school";
import { PageNum, School, ExamRoom } from "@/tool/interface-index";
import * as cls from "@/tool/_class";
import * as _ from "lodash";
import { UserModule } from "@/store/modules/user";
import SchoolContractEditor from "@/views/school-management/school-contract-editor.vue";
import dayjs from "dayjs";
import { handleClipboard, clipboardSuccess } from "@/utils/clipboard"; // use clipboard directly
import SplitPane from "vue-splitpane";

class SchoolListItem extends cls.ExamSchool {
  hasChildren: boolean = true;
  children: ExamRoom[] = [];
}

@Component({
  name: "SchoolListPage",
  components: {
    cardBox,
    pagination,
    SchoolContractEditor,
    SplitPane,
  },
  methods: {
    resize() {
      //
    },
  },
})
export default class extends Vue {
  private multipleSelection: SchoolListItem[] = [];

  private activeTabName: string = "treeGroup";
  private filterAreaText: string = "";
  private areas: any[] = [];

  @Watch("filterAreaText")
  private handleFilterTextChange(val: string) {
    (this.$refs.tree as any).filter(val);
  }

  private clipboardSuccess = clipboardSuccess;
  private handleClipboard = handleClipboard;

  private schoolContractTypeOptions = _.concat(
    [
      {
        label: "无合约",
        value: "",
      },
    ],
    cls.schoolContractTypeOptions
  );

  private schoolContractValidDaysOptions: { label: string; value: string }[] = [
    {
      value: "-1",
      label: "已过期",
    },
    {
      value: "7",
      label: "7天内有效",
    },
    {
      value: "15",
      label: "15天内有效",
    },
    {
      value: "30",
      label: "1个月内有效",
    },
    {
      value: "180",
      label: "半年内有效",
    },
    {
      value: "360",
      label: "一年内有效",
    },
  ];

  private schoolSectionOptions: { label: string; value: string }[] = [
    {
      value: "primarySchool",
      label: "小学",
    },
    {
      value: "middleSchool",
      label: "中学",
    },
    {
      value: "highSchool",
      label: "高中",
    },
  ];
  private query: {
    schoolName?: string;
    areaId?: string;
    schoolId?: string;
    keyWords?: string;
    schoolContractValidDays?: number;
    schoolSections: string[];
    areaIds: string[];
    schoolContractTypes: string[];
  } = {
    schoolName: "",
    schoolSections: [],
    areaIds: [],
    schoolContractTypes: [],
  };
  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10,
  };
  private tableData: Array<SchoolListItem> = [];

  private copyData(school: School): string {
    return `${school.schoolName}\n${school.schoolName}:${school.registrationCode}\n${school.contactPerson}:${school.contactPersonPhone}`;
  }

  private get disabledOperation(): boolean {
    console.log(`roles:${UserModule.roles}`);
    if (this.$route.meta.roles) {
      let roled: boolean = false;
      for (let i = 0; i < this.$route.meta.roles.length; i++) {
        roled =
          roled ||
          UserModule.roles.some((item) => item == this.$route.meta.roles[i]);
        if (roled) {
          break;
        }
      }
      if (!roled) {
        return true;
      }
      console.log(`meta.roles:${this.$route.meta.roles},roled:${roled}`);

      if (
        UserModule.roles.some(
          (item) => item == "admin" || item == "platformAdmin"
        )
      ) {
        return false;
      }

      /*
      return (
        UserModule.roles.findIndex((role: string) => {
          return role.toLowerCase() == "teacher";
        }) >= 0
      );
      */
    }
    return true;
  }

  private get disabledContract(): boolean {
    return !UserModule.roles.some((role: string) => {
      return role.toLowerCase() == "admin";
    });
  }

  private get disabledGotoExamRoom(): boolean {
    return !UserModule.roles.some((role: string) => {
      return role.toLowerCase() == "admin";
    });
  }

  private get schoolContractEditor(): SchoolContractEditor {
    return this.$refs.schoolContractEditor as SchoolContractEditor;
  }

  handleSelectionChange(val: SchoolListItem[]) {
    this.multipleSelection = val;
  }

  private handleTabClick(tabName: string) {
    switch (tabName) {
      case "treeGroup": {
        break;
      }
    }
  }

  private filterAreaNode(value: string, data: any) {
    if (!value) return true;
    return data.keyValue.indexOf(value) !== -1;
  }

  private getQueryAreaIds(cascades: any[]) {
    let i: number = 0;
    while (i < cascades.length) {
      if (!this.query.areaIds.some((item) => item === cascades[i].keyId)) {
        this.query.areaIds.push(cascades[i].keyId);
      }
      //
      switch (_.get(cascades[i], "dataType", "")) {
        case "area": {
          if (_.get(cascades[i], "children", []).length == 0) {
            //
          } else {
            this.getQueryAreaIds(cascades[i].children);
          }
          i++;
          break;
        }
        default:
          i++;
      } //switch
    }
  }

  private handleAreaNodeClick(data: any, node: any) {
    this.query.areaIds.splice(0, this.query.areaIds.length);
    this.getQueryAreaIds([data]);

    this.getSchoolData();
  }

  private schoolContractTypeLabel(schoolContractType: string) {
    let label: string = "";
    const idx = cls.schoolContractTypeOptions.findIndex((item) => {
      return schoolContractType == item.value;
    });
    if (idx >= 0) {
      return cls.schoolContractTypeOptions[idx].label;
    }
    return "";
  }

  private schoolContractEndDateToNow(school: SchoolListItem): string {
    return dayjs(school.schoolContractEndDate).fromNow();
  }

  private schoolContractValidDays(school: SchoolListItem): number {
    if (!school.schoolContractId) {
      return 0;
    }
    if (
      !(
        dayjs(school.schoolContractStartDate).isValid() &&
        dayjs(school.schoolContractEndDate).isValid()
      )
    ) {
      return 0;
    }
    return dayjs(school.schoolContractEndDate).diff(dayjs(new Date()), "day");
  }

  private onSubmit() {
    console.log("submit!");
  }

  private deleteData(schoolId: string) {
    this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        SchoolApi.deleteSchool(schoolId).then((res: any) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getSchoolData();
          }
        });
      })
      .catch(() => {});
  }

  private doRegisterClick(school: SchoolListItem) {
    this.$prompt("请输入授权码", "注册提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      closeOnClickModal: false,
      inputValue: school.registrationCode,
      inputValidator: (value: string) => {
        if (!value) {
          return "授权码不能为空。";
        }
        return true;
      },
    })
      .then((data: any /*MessageBoxInputData*/) => {
        const req: SchoolListItem = _.merge(new SchoolListItem(), school);
        req.registrationCode = data.value;
        SchoolApi.register(req).then(({ data }) => {
          school.registered = true;
          school.registrationCode = req.registrationCode;
          this.$message({
            type: "success",
            message: "注册成功。 ",
          });
        });
      })
      .catch(() => {
        /*
      this.$message({
        type: 'info',
        message: '取消输入'
      });     
      */
      });
  }

  private handleSchoolContractConfirmed(schoolContracts: cls.SchoolContract[]) {
    for (let i = 0; i < schoolContracts.length; i++) {
      const schoolContract = schoolContracts[i];
      const idx = this.tableData.findIndex((item) => {
        return item.schoolId == schoolContract.schoolId;
      });
      if (idx >= 0) {
        const school = this.tableData[idx];
        school.schoolContractId = schoolContract.schoolContractId;
        school.schoolContractType = schoolContract.schoolContractType;
        school.schoolContractStartDate = schoolContract.schoolContractStartDate;
        school.schoolContractEndDate = schoolContract.schoolContractEndDate;
      }
    }
  }

  private doTestClick() {
    const a = { test: "xxxx" };
    const ans = a?.test || "te";
    if (ans) {
      alert(ans);
    } else {
      alert("false");
    }
  }

  private doNewContractClick() {
    if (this.multipleSelection.length == 0) {
      this.$message({
        message: "请先选择学校。",
        type: "warning",
      });
      return;
    }
    const schoolIds: string[] = [];
    this.multipleSelection.forEach((item) => {
      schoolIds.push(item.schoolId);
    });
    this.schoolContractEditor.showBatchNew(schoolIds);
  }

  private async doDeleteSchoolContract(school: SchoolListItem) {
    const { data } = await SchoolApi.getSchoolContractList({
      schoolContractId: school.schoolContractId,
      curPage: 1,
      pageSize: 1,
    });
    if (data.items.length == 1) {
      const contract: cls.SchoolContractItem = data.items[0];
      if (contract.marked) {
        this.$message({
          type: "warning",
          message: "合约已备注为“记录购买”，不能删除！",
        });
        return;
      }
    } else {
      this.$message({
        type: "warning",
        message: "合约不存在！",
      });
      return;
    }

    await this.$confirm(
      `删除学校"${school.schoolName}"当前合约, 是否继续?`,
      "提示",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }
    );

    await SchoolApi.deleteSchoolContract(school.schoolId);
    school.schoolContractId = "";
    school.schoolContractType = cls.ContractType.unknown;
    //school.schoolContractStartDate = null;
    //school.schoolContractEndDate = null;
    this.$message({
      type: "success",
      message: "删除合约成功!",
    });
  }

  private async handleContractCommand(command: string, school: SchoolListItem) {
    switch (command.toLocaleLowerCase()) {
      case "new": {
        await this.schoolContractEditor.show(school.schoolId, "");
        break;
      }
      case "edit": {
        await this.schoolContractEditor.show(
          school.schoolId,
          school.schoolContractId
        );
        break;
      }
      case "delete": {
        await this.doDeleteSchoolContract(school);
        break;
      }
    }
  }

  private doUnregisterClick(school: SchoolListItem) {
    this.$confirm(
      `学校“${school.schoolName}”取消注册, 是否继续?`,
      "取消注册提示",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }
    )
      .then(() => {
        SchoolApi.unregister(school).then(({ data }) => {
          school.registered = false;
          this.$message({
            type: "success",
            message: "取消注册成功。 ",
          });
        });
      })
      .catch(() => {
        /*
      this.$message({
        type: 'info',
        message: '已取消删除'
      });   
      */
      });
  }

  private loading: boolean = false;
  private async getSchoolData() {
    try {
      this.loading = true;
      const res = await SchoolApi.getSchoolList(
        _.merge(
          {
            pageSize: this.pageNum.pageSize,
            curPage: this.pageNum.curPage,
          },
          this.query
        )
      );
      this.tableData.splice(0, this.tableData.length);
      res.data.items.forEach((element: any) => {
        let item: SchoolListItem = new SchoolListItem();
        _.merge(item, element);
        this.tableData.push(item);
        console.log(`school item: ${JSON.stringify(item)}`);
      });
      this.tableData = res.data.items;
      this.pageNum.totalPage = res.data.totalPage;
    } finally {
      this.loading = false;
    }
  }

  querySchoolClick() {
    this.pageNum.curPage = 1;
    this.getSchoolData();
  }

  private upDataPage() {
    this.getSchoolData();
  }

  private doGoToExamRoomClick(school: SchoolListItem) {
    this.$router.push({
      path: "examRoomList",
      query: {
        schoolName: school.schoolName,
      },
    });
  }

  private setSchool(schoolId: string, type: string) {
    this.$router.push({
      path: "createSchool",
      query: {
        schoolId: schoolId,
        type: type,
      },
    });
  }
  private removeNullChildren(cascades: any[]) {
    let i: number = 0;
    while (i < cascades.length) {
      switch (_.get(cascades[i], "dataType", "")) {
        case "school": {
          if (_.get(cascades[i], "children", []).length == 0) {
            delete cascades[i]["children"];
          } else {
            this.removeNullChildren(cascades[i].children);
          }
          i++;
          break;
        }
        case "area": {
          if (_.get(cascades[i], "children", []).length == 0) {
            delete cascades[i]["children"];
            //cascades.splice(i, 1);
          } else {
            this.removeNullChildren(cascades[i].children);
          }
          i++;
          break;
        }
        default:
          i++;
      } //switch
    }
  }

  private async __init() {
    const { data } = await SchoolApi.getAreaCascadeList();
    this.areas = data.items;
    this.removeNullChildren(this.areas);
  }
  activated() {
    this.getSchoolData();
  }
  mounted() {
    this.__init();
  }
  private loadExamRoom(tree: any, treeNode: any, resolve: any) {
    resolve([]);
  }
}
