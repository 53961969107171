



























import echarts, { EChartOption } from "echarts";
import { Component, Prop, Watch, Emit } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ResizeMixin from "@/components/Charts/mixins/resize";
import * as _ from "lodash";
import cardBox from "@/components/card-box/index.vue";
import * as rpt from "@/api/examProjectReport";
import { CommentParams, ScoreStatistics } from "@/classes/exam-comment";

interface ExamPaperQuestionScoreItem extends ScoreStatistics {
  examPaperName: string;
  sectionName: string;
  questionName: string;
}

const animationDuration = 6000;

interface Serie {
  name: string;
  data: { name: string; value: number; dataKey?: string }[];
  itemStyle?: Object;
}

interface IBarChartData {
  series: Serie[];
}

@Component({
  name: "StatisticsExamPaperQuestion",
  components: {
    cardBox,
  },
})
export default class extends mixins(ResizeMixin) {
  @Prop({ default: () => new CommentParams() }) private params!: CommentParams;
  @Emit("chartClick") handleClick(params: any) {}

  private chartWidth: string = "100%";
  private chartHeight: string = "300px";
  private chartClassName: string = "chart";
  private chartRef = "chart";
  private chartData: IBarChartData = {
    series: [],
  };

  private title: string = "";

  private questionData: ExamPaperQuestionScoreItem[] = [];

  private async __init() {
    if (!this.params.examProjectId) {
      return;
    }
    const { data } = await rpt.getExamPaperQuestionScoreReport(this.params);
    this.questionData = data.items.sort(
      (a: ExamPaperQuestionScoreItem, b: ExamPaperQuestionScoreItem) => {
        const s1 = a.examPaperName + a.sectionName + a.questionName;
        const s2 = b.examPaperName + b.sectionName + b.questionName;
        if (s1 < s2) {
          return -1;
        } else if (s1 > s2) {
          return 1;
        }
        return 0;
      }
    );
    //
    this.chartData.series.splice(0, this.chartData.series.length);
    _.forEach(
      _.groupBy(this.questionData, (item) => item.examPaperName),
      (value: ExamPaperQuestionScoreItem[], key: string) => {
        const serie: Serie = {
          name: key,
          data: [],
        };
        for (let i = 0; i < value.length; i++) {
          serie.data.push({
            name: value[i].questionName,
            value: Math.round( value[i].scoringRate * 10000)/100.00,
          });
        }
        this.chartData.series.push(serie);
      }
    );

    this.setOptions(this.chartData);
    //
    console.log(`chartData: ${JSON.stringify(this.chartData)}`);
  }

  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
    this.__init();
  }

 

  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }

  private initChart() {
    this.$refs[this.chartRef];
    this.chart = echarts.init(
      /*this.$el*/ this.$refs[this.chartRef] as HTMLDivElement,
      "macarons"
    );
    this.setOptions(this.chartData);
    this.chart.on("chartClick", this.handleClick);
  }

  private setOptions(chartData: IBarChartData) {
    let xAxisData: string[] = [];
    let legendData: string[] = [];
    let series: any[] = [];
    for (let i = 0; i < chartData.series.length; i++) {
      let xData: string[] = chartData.series[i].data.map((item) => {
        return item.name;
      });
      legendData.push(chartData.series[i].name);
      xAxisData.push(...xData);
      series.push(
        _.merge(
          {
            name: "",
            type: "bar",
            //stack: "vistors",
            barWidth: '60%',
            data: [],
            animationDuration,
          },
          chartData.series[i]
        )
      );
    }
    xAxisData = _.uniq(xAxisData);
    //
    if (this.chart) {
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: 30,
          left: "2%",
          right: "2%",
          bottom: "3%",
          containLabel: true,
        },
        legend: {
          data: legendData, //["expected", "actual"],
        },
        xAxis: [
          {
            type: "category",
            data: xAxisData, //["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisLabel: {
              rotate: 30,
            },
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false,
            },
          },
        ],
        series: series ,
        color: [
          "#5470c6",
          "#91cc75",
          "#fac858",
          "#ee6666",
          "#73c0de",
          "#3ba272",
          "#fc8452",
          "#9a60b4",
          "#ea7ccc",
        ],
      };
      this.chart!.setOption(option as EChartOption<EChartOption.SeriesBar>);
    }
  }
}
