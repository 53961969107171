import request from '@/utils/request'

export const getExamProjectSchoolList = (params: {
  examSchoolId?: string,
  examProjectId?: string,
  examProjectSchoolId?: string,
  pageSize: number,
  curPage: number
}) =>
  request({
    // 获取考试项目考点列表
    url: '/v1/examProjectSchoolList',
    method: 'GET',
    params
  })

export const getExamProjectList = (params: {
  examProjectId?: string,
  examSchoolId?: string,
  examType?: string,
  examTypes?: string[],
  examStartTimeWithinDays?: number,
  keyWords?: string,
  mergeExamSchools?: boolean,
  pageSize: number,
  curPage: number
}) =>
  request({
    // 获取考试项目列表
    url: '/v1/examProjectList',
    method: 'GET',
    params
  })

export const copyExamProject = (examProjectId: string, data: {
  newExamProjectId?: string,
  newExamProjectName: string,
  copyExamPapers: boolean,
  copyExamStudents: boolean,
}, disabledTips: boolean = false) =>
  request({
    // 添加/更新考试项目列表
    url: `/v1/examProjectProfile/copy/${examProjectId}`,
    method: 'POST',
    data,
    params: {
      disabledTips
    }
  })

export const setExamProject = (data: any) =>
  request({
    // 添加/更新考试项目列表
    url: '/v1/examProject',
    method: 'POST',
    data
  })

export const queryExamProject = (examProjectId: string) =>
  request({
    //  获取考试项目by examProjectId
    url: `/v1/examProject/${examProjectId}`,
    method: 'GET'
  })

export const deleteExamProject = (examProjectId: string) =>
  request({
    // 删除考试项目by examProjectId
    url: `/v1/examProject/${examProjectId}`,
    method: 'DELETE'
  })

export const queryExamProjectProfile = (examProjectId: string) =>
  request({
    //  获取考试项目详细信息by examProjectId
    url: `/v1/examProjectProfile/${examProjectId}`,
    method: 'GET'
  })

/**
* 测评考试项目
* @param data 
* @param disabledTips 
* @returns 
*/
export const postScoreExamProject = (data: {
  examProjectId: string,
  examSchoolId?: string,
  examTimeId?: string,
  scoreExamAnswerPackages?: boolean,
  scoreLocalExamAnswerPackageFiles?: boolean,
  recipients: string
}, disabledTips: boolean = false) => request({
  url: '/v1/scoring/examProject',
  method: 'post',
  headers: {
    "Content-Type": "application/json",
  },
  data,
  timeout: 150000,
  params: {
    disabledTips: disabledTips
  }

});

/**
* 清除超过期限考试项目数据
* @param data 
* @param disabledTips 
* @returns 
*/
export const postCleanHistoryProject = (data: {
  examProjectId?: string,
  examState?: string,
  examType?: string,
  examSchoolId?: string,
  keyWords?: string,
  examStartTimeWithinDays?: number,
  examStartTimeWithOutDays?: number
}, disabledTips: boolean = false) => request({
  url: '/v1/cleanHistoryProject',
  method: 'post',
  headers: {
    "Content-Type": "application/json",
  },
  data,
  params: {
    disabledTips: disabledTips
  }
});
