import { Interface } from "readline"

export interface ClassList {
  classId: string
  className: string
  schoolId: string
  schoolName: string
}
export interface ExamPaper {
  examProjectId: string
  examPaperId: string
  examPaperNo: string
  examPaperName: string
  examPaperUnzipCode: string
  examPaperFileName: string
  sourceType: {
    paperFileName: string
    validateCodeFileName: string
  }
  examStartTime: string
  examEndTime: string
  examPaperFileUrl: string
  examStartTimeStr: string
  examEndTimeStr: string
}
export interface ExamProject {
  examProjectId: string
  examProjectName: string
  examType: string
  code: string,
  num01: number,
  examState: string
  examParams: {
    allowLateStart: boolean
    allowLateStartMaxMinutes: number
    studentExamPaperRule: string
    allowAdvanceStop: boolean
    allowAdvanceStopMaxMinutes: number
    showRaiseHand: boolean
    lockWin: boolean
    fullScreen: boolean
    showExamNotice: boolean
    showSeatNumber: boolean
    skipRecordingTest: boolean
    strictCheckAnswerPackage: boolean
    skipEnvironmentCheck: boolean
    autoExamTimes: boolean
  }
  examProjectNo: string
  examStartTime: string
  examEndTime: string
  examStartTimeStr: string
  examEndTimeStr: string
  examPapers: Array<ExamPaper>
  examSchools: Array<School>
}

export interface PageNum {
  totalPage: number
  curPage: number
  pageSize: number
}

/**
 * @typedef {Object} School
 * @property {string} schoolId 学校Id
 * @property {number} classRoomLimitNumber 教室授权数
 * @property {number} examRoomLimitNumber 机房授权数
 * @property {number} teacherAccountLimitNumber 教师帐号授权数
 */
export interface School {
  schoolId: string,
  schoolName: string,
  primarySchool: boolean,
  middleSchool: boolean,
  highSchool: boolean,
  address: string,
  contactPerson: string,
  contactPersonPhone: string,
  contactPersonEmail: string,
  contactPerson02: string,
  contactPersonPhone02: string,
  contactPersonEmail02: string,
  areaId: string,
  areaName: string,
  registrationCode: string,
  classRoomLimitNumber: number,
  examRoomLimitNumber: number,
  teacherAccountLimitNumber: number,
}
export interface ExamTime {
  examTimeId: string,
  examTimeName: string,
  examTimeNo: number,
  examProjectId: string,
  examState: string,
  standardStartTime: string,
  standardEndTime: string,
  realStartTime: string,
  realEndTime: string,
  standardStartTimeStr: string,
  standardEndTimeStr: string,
  realStartTimeStr: string,
  realEndTimeStr: string
}
export interface ExamStudent {
  examAccount: string,
  studentName: string,
  schoolId?: string,
  schoolName: string,
  classId?: string,
  className: string,
  sex: string,
  photoFileName: string,
  photoFileUrl?: string,
  str01: string
}

export enum UpgradeLevel { none = "none", hint = "hint", confirm = "confirm", required = "required" };
export interface ExamRoom {
  examRoomId: string,
  examRoomName: string,
  schoolId: string,
  schoolName?: string,
  contactPerson: string,
  contactPersonPhone: string,
  contactPersonEmail: string,
  contactPerson02: string,
  contactPersonPhone02: string,
  contactPersonEmail02: string,
  registrationCode: string,
  softwareVersion: string,
  upgradeLevel: UpgradeLevel
}

export interface ClassRoom {
  classRoomId: string,
  classRoomName: string,
  schoolId: string,
  contactPerson: string,
  contactPersonPhone: string,
  contactPersonEmail: string,
  contactPerson02: string,
  contactPersonPhone02: string,
  contactPersonEmail02: string,
  registrationCode: string,
  softwareVersion: string,
  upgradeLevel: UpgradeLevel
}

export interface ExamAnswerRecord {

  examAnswerRecordId: string,
  examStudentId: string,
  examPaperId: string,
  examProjectId: string,
  examTimeId: string,
  questionId: string,
  questionNo: string,
  questionName: string,
  answer: string,
  answerFileUrl: string,
  fileHashCode: string,
  examQuestionType: string,
  examProjectName: string,
  examAreaId: string,
  examAreaName: string,
  examSchoolId: string,
  examSchoolName: string,
  examRoomId: string,
  examRoomName: string,
  examTimeName: string,
  examAccount: string,
  str01: string,
  str02: string,
  standardScore: number,
  score: number


}




