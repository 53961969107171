































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import SchoolContractReturnedDlg from "./school-contract-returned.vue";
import SchoolContractSharedDlg from "./school-contract-shared.vue";
import pagination from "@/components/pagination/index.vue";
import { PageNum, School } from "@/tool/interface-index";
import {
  SchoolContract,
  SchoolContractItem,
  schoolContractTypeOptions,
  ContractType
} from "@/tool/_class";
import { getSchoolContractList, deleteSchoolContract } from "@/api/school";
import * as _ from "lodash";
import { UserModule } from "@/store/modules/user";
import { handleClipboard, clipboardSuccess } from "@/utils/clipboard"; // use clipboard directly
import { exportJson2Excel } from "@/utils/excel";
import { formatJson } from "@/utils";
import dayjs from "dayjs";
import SchoolContractEditor from "@/views/school-management/school-contract-editor.vue";

@Component({
  name: "SchoolContractListPage",
  components: {
    cardBox,
    pagination,
    SchoolContractReturnedDlg,
    SchoolContractSharedDlg,
    SchoolContractEditor,
  },
})
export default class extends Vue {
  private multipleSelection: SchoolContractItem[] = [];

  private query: {
    sharedPercentBetweens?: number[];
    schoolContractTypes?: string[];
    keywords: string;
  } = {
    schoolContractTypes: [ContractType.specialoffer,ContractType.standard,ContractType.plus],
    keywords: "",
  };
  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10,
  };
  private tableData: Array<SchoolContractItem> = [];

  private dayjs = dayjs;
  private schoolContractTypeOptions = schoolContractTypeOptions;

  private clipboardSuccess = clipboardSuccess;
  private handleClipboard = handleClipboard;

  private get disabledOperation(): boolean {
    console.log(`roles:${UserModule.roles}`);
    if (this.$route.meta.roles) {
      let roled: boolean = false;
      for (let i = 0; i < this.$route.meta.roles.length; i++) {
        roled =
          roled ||
          UserModule.roles.some((item) => item == this.$route.meta.roles[i]);
        if (roled) {
          break;
        }
      }
      if (!roled) {
        return true;
      }
      console.log(`meta.roles:${this.$route.meta.roles},roled:${roled}`);

      if (
        UserModule.roles.some(
          (item) => item == "admin" || item == "platformAdmin"
        )
      ) {
        return false;
      }

      /*
        return (
          UserModule.roles.findIndex((role: string) => {
            return role.toLowerCase() == "teacher";
          }) >= 0
        );
        */
    }
    return true;
  }

  private schoolContractTypeFormatter(row: SchoolContractItem, column: any) {
    return (
      this.schoolContractTypeOptions.find(
        (item) => item.value == row.schoolContractType
      )?.label || row.schoolContractType
    );
  }

  private schoolContractTypeLabel(schoolContractType: string) {
    let label: string = "";
    const idx = schoolContractTypeOptions.findIndex((item) => {
      return schoolContractType == item.value;
    });
    if (idx >= 0) {
      return schoolContractTypeOptions[idx].label;
    }
    return "";
  }

  private get schoolContractReturnedDlg(): SchoolContractReturnedDlg {
    return this.$refs.schoolContractReturnedDlg as SchoolContractReturnedDlg;
  }

  private get schoolContractSharedDlg(): SchoolContractSharedDlg {
    return this.$refs.schoolContractSharedDlg as SchoolContractSharedDlg;
  }

  private handleSchoolContractReturnedDetailsClose(params: {
    schoolContractId: string;
    returnedPercent: number;
  }) {
    const contract = this.tableData.find(
      (item) => item.schoolContractId == params.schoolContractId
    );
    if (contract) {
      contract.returnedPercent = params.returnedPercent;
    }
  }
  private doSchoolContractReturnedDetailsClick(schoolContractId: string) {
    if (this.schoolContractReturnedDlg) {
      this.schoolContractReturnedDlg.show(schoolContractId);
    } else {
    }
  }

  private handleSchoolContractSharedDetailsClose(params: {
    schoolContractId: string;
    sharedPercent: number;
  }) {
    const contract = this.tableData.find(
      (item) => item.schoolContractId == params.schoolContractId
    );
    if (contract) {
      contract.sharedPercent = params.sharedPercent;
    }
  }

  private doSchoolContractSharedDetailsClick(schoolContractId: string) {
    if (this.schoolContractSharedDlg) {
      this.schoolContractSharedDlg.show(schoolContractId);
    } else {
    }
  }

  private copyData(examRoom: ExamRoom): string {
    return `${examRoom.schoolName}\n${examRoom.examRoomName}:${examRoom.registrationCode}\n${examRoom.contactPerson}:${examRoom.contactPersonPhone}`;
  }

  handleSelectionChange(val: SchoolContractItem[]) {
    this.multipleSelection = val;
  }

  private doClipboardSelectionClick(event: MouseEvent) {
    if (this.multipleSelection.length == 0) {
      this.$message({
        message: "请先选择学校。",
        type: "warning",
      });
      return;
    }

    let text: string = "";
    for (let i = 0; i < this.multipleSelection.length; i++) {
      const examRoom = this.multipleSelection[i];
      const tmp = this.copyData(examRoom);
      if (text) {
        text = `${text}\n\n${tmp}`;
      } else {
        text = tmp;
      }
    } //for
    this.handleClipboard(text, event);
  }

  private deleteData(schoolContractId: string) {
    this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        deleteSchoolContract(schoolContractId).then((res: any) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getSchoolContractListData();
          }
        });
      })
      .catch(() => {});
  }
  private loading: boolean = false;
  private async getSchoolContractListData() {
    try {
      this.loading = true;
      let params = _.merge(
        {
          pageSize: this.pageNum.pageSize,
          curPage: this.pageNum.curPage,
        },
        this.query
      );
      if (
        this.query.sharedPercentBetweens &&
        this.query.sharedPercentBetweens.length == 2 &&
        this.query.sharedPercentBetweens[0] == 0 &&
        this.query.sharedPercentBetweens[1] == 100
      ) {
        params = _.omit(params, ["sharedPercentBetweens"]);
      }
      const res = await getSchoolContractList(params);
      this.tableData = res.data.items;
      this.pageNum.totalPage = res.data.totalPage;
    } finally {
      this.loading = false;
    }
  }

  private upDataPage() {
    this.getSchoolContractListData();
  }

  private handleSchoolContractConfirmed(schoolContracts: SchoolContract[]) {
    for (let i = 0; i < schoolContracts.length; i++) {
      const schoolContract = schoolContracts[i];
      const idx = this.tableData.findIndex((item) => {
        return item.schoolContractId == schoolContract.schoolContractId;
      });
      if (idx >= 0) {
        const found = this.tableData[idx];
        _.merge(found, schoolContract);
      }
    }
  }

  private get schoolContractEditor(): SchoolContractEditor {
    return this.$refs.schoolContractEditor as SchoolContractEditor;
  }

  private setSchoolContract(schoolContract: SchoolContractItem, type: string) {
    this.schoolContractEditor.show(
      schoolContract.schoolId,
      schoolContract.schoolContractId
    );
  }

  private exportSchoolContract(data: Array<SchoolContractItem>) {
    const tHeader = [
      "合约编号",
      "学校",
      "类型",
      "开始日期",
      "结束日期",
      "资源模板",
      "已回款%",
      "已分成%",
      "记录分成",
    ];
    const filterVal = [
      "schoolContractNo",
      "schoolName",
      "schoolContractType",
      "schoolContractStartDate",
      "schoolContractEndDate",
      "resourceTemplateName",
      "returnedPercent",
      "sharedPercent",
      "marked",
    ];
    const data1 = formatJson(filterVal, data);
    exportJson2Excel(tHeader, data1, `合约清单(PAGE${this.pageNum.curPage})`);
  }

  exportSchoolContractClick() {
    this.$confirm(
      "非公开数据，需确保数据安全，导出数据需保密, 是否继续?",
      "提示",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }
    ).then(() => {
      this.exportSchoolContract(this.tableData);
    });
  }

  querySchoolContractClick() {
    this.pageNum.curPage = 1;
    this.getSchoolContractListData();
  }

  activated() {
    if (this.$route.query.keywords) {
      this.query.keywords = this.$route.query.keywords as string;
    }
    this.getSchoolContractListData();
  }

  mounted() {}
}
