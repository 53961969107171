import request from '@/utils/request'
import { Message, MessageBox } from 'element-ui'
import axios from 'axios';
import { AxiosRequestConfig } from "axios";
import * as func from "@/public/base_func";
import { UserModule } from '@/store/modules/user';
import { config } from '@vue/test-utils';
import * as _ from "lodash";

export function download(url: string, params: any, filename: string, authType: string = 'base', blobOption: BlobPropertyBag = {
  type: 'application/vnd.ms-excel'
}) {
  //Message.warning('导出数据中')
  let config: AxiosRequestConfig = {
    params: params,
    responseType: 'arraybuffer',
    timeout: 180000,
    maxRedirects: 1
  }
  switch (authType.toLowerCase()) {
    case "base": {
      _.merge(config, {
        auth: {
          username: 'super',
          password: func.backEndAuthDynamicPassword()//'super123'
        },
      })
      break;
    }
    case "oauth2": {
      _.merge(config, {
        headers: {
          authorization: `bearer ${UserModule.token}`
        }
      })
    }
  }

  return /*request*/axios.get(url, config).then((r) => {
    const content = r.data
    const blob = new Blob([content], blobOption)
    if ('download' in document.createElement('a')) {
      const elink = document.createElement('a')
      elink.download = filename
      elink.style.display = 'none'
      elink.href = URL.createObjectURL(blob)
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
      //Message.success('导出成功')
    }
  }).catch((r) => {
    console.error(r)
    Message.error('导出失败，请检查网络。')
  })

}

export async function downloadAsBlob(url: string, params: any, blobOption: BlobPropertyBag = {}): Promise<Blob> {
  const resp = await /*request*/axios.get(url, {
    params,
    responseType: 'arraybuffer',
    auth: {
      username: "super",
      password: func.backEndAuthDynamicPassword()//'super123'
    }
  });

  return new Blob([resp.data], blobOption);
}
