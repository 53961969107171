import { render, staticRenderFns } from "./school-contract-editor.vue?vue&type=template&id=53ed9f2c&scoped=true&"
import script from "./school-contract-editor.vue?vue&type=script&lang=ts&"
export * from "./school-contract-editor.vue?vue&type=script&lang=ts&"
import style0 from "./school-contract-editor.vue?vue&type=style&index=0&id=53ed9f2c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ed9f2c",
  null
  
)

export default component.exports