
































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import StatisticsExamPaper from "./components/statistics-exam-paper.vue";
import StatisticsExamPaperSection from "./components/statistics-exam-paper-section.vue";
import StatisticsExamScoreSection from "./components/statistics-exam-score-section.vue";
import StatisticsExamClass from "./components/statistics-exam-class.vue";
import { CommentParams } from "@/classes/exam-comment";
import * as ResApi from "@/api/examFileResource";

import * as rpt from "@/api/examProjectReport";

@Component({
  name: "ExamProjectReport",
  components: {
    cardBox,
    StatisticsExamPaper,
    StatisticsExamPaperSection,
    StatisticsExamScoreSection,
    StatisticsExamClass,
  },
})
export default class extends Vue {
  private title: string = "项目报告";

  private exportBox = "export-box";

  private params: CommentParams = new CommentParams();
  private examProjectName: string = "";
  private componentKey0: number = 0;
  private componentKey1: number = 1;
  private componentKey2: number = 2;
  private componentKey3: number = 3;

  private refreshComponentKeys() {
    this.componentKey3 += 1;
    this.componentKey2 += 1;
    this.componentKey1 += 1;
    this.componentKey0 += 1;
  }

  private doExportClick() {
    //rpt.downloadExamProjectReport(this.params)
    //exportToDocx(this.exportBox, `项目报告.docx`);
    try {
      const baseUrl: string = process.env.VUE_APP_BASE_API || "";
      ResApi.download(
        `${baseUrl}${
          baseUrl.endsWith("/") ? "" : "/"
        }v2/statistics/download/examProjectReport?examProjectId=${
          this.params.examProjectId
        }&examSchoolId=${this.params.examSchoolId}`,
        {},
        `${
          this.examProjectName + (this.examProjectName ? "_" : "")
        }项目报告.docx`,
        "oauth2",
        {
          type: "application/msword",
        }
      );
    } catch (error) {
      //
    }
  }

  private doGoBackClick() {
    this.$router.go(-1);
  }

  mounted() {
    this.params.examProjectId =
      (this.$route.query.examProjectId as string) || "";
    this.examProjectName = (this.$route.query.examProjectName as string) || "";
    this.title = "项目报告";
    if (this.examProjectName) {
      this.title = `${this.examProjectName} - 项目报告`;
    }
    //
    console.log(
      `exam project report mounted done: ${JSON.stringify(this.params)}`
    );
    this.refreshComponentKeys();
  }
}
