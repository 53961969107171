

















































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import pagination from "@/components/pagination/index.vue";
import { ExamTime, PageNum, School } from "@/tool/interface-index";
import { ExamProject } from "@/tool/interface-index";
import * as _ from "lodash";
import * as CommentApi from "@/api/examComment";
import * as ExamPrjApi from "@/api/examProject";
import {
  CommentParams,
  ExamTimesStatisticsReport,
  ExamTimeScoreStatistics,
} from "@/classes/exam-comment";
import { getSchoolCascadeList } from "@/api/school";
import { getExamProjectList } from "@/api/examProject";
import * as ResApi from "@/api/examFileResource";
import { exportJson2Excel } from "@/utils/excel";
import { formatJson } from "@/utils";
import { repeat } from "lodash";
import dayjs from "dayjs";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate",
]);

@Component({
  name: "MockExamAutoScoreListPage",
  components: {
    cardBox,
    pagination,
  },
})
export default class extends Vue {
  private dayjs = dayjs;
  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10,
  };

  private items: any[] = [];
  private examProjectList: ExamProject[] = [];
  private examTimeGroupList: { value: string; label: string }[] = [];

  private query: {
    keyWords: string;
    examSchoolId: string;
    examProjectId: string;
    examTimeId: string;
    examStartTimeWithinDays: number;
  } = {
    examSchoolId: "",
    keyWords: "",
    examProjectId: "",
    examTimeId: "",
    examStartTimeWithinDays: 30, //30,
  };

  private schools: any[] = [];

  private loading: boolean = false;
  private examTimesStatistics: ExamTimesStatisticsReport | undefined =
    undefined;

  private get examType(): string {
    return "mockExamMode";
    const paths = this.$route.path.split("/");
    const isMockExamMode =
      paths.findIndex((value: string) => {
        return value.toLowerCase() == "mockExamManagement".toLowerCase();
      }) >= 0;
    if (isMockExamMode) {
      return "mockExamMode";
    }
    return "formalExamMode";
  }

  private displayLoading: boolean = false;
  private async getExamAutoScoreListData(displayLoading: boolean) {
    if (this.loading) {
      return;
    }
    try {
      this.loading = true;
      this.displayLoading = displayLoading;
      //
      const { data } = await CommentApi.getExamTimesReport({
        pageSize: this.pageNum.pageSize,
        curPage: this.pageNum.curPage,
        keyWords: this.query.keyWords,
        examSchoolId: this.query.examSchoolId,
        examProjectId: this.query.examProjectId,
        examType: this.examType,
        examStartTimeWithinDays: this.query.examStartTimeWithinDays,
      });
      this.items.splice(0, this.items.length);
      this.pageNum.totalPage = data.totalPage;
      this.examTimesStatistics = data;
      this.examTimesStatistics!.items.forEach(
        (item: ExamTimeScoreStatistics) => {
          this.items.push(item);
        }
      );

      this.$nextTick(() => {
        (this.$refs.examAutoScoreTableView as any).doLayout();
      });
    } finally {
      this.loading = false;
    }
  }

  private upDataPage() {
    this.getExamAutoScoreListData(true);
  }

  queryAutoScoresClick() {
    this.pageNum.curPage = 1;
    this.getExamAutoScoreListData(true);
  }

  async doReScoringAllClick() {
    await this.$confirm(`重评当前所有统计结果, 是否继续?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    });
    for (let i = 0; i < this.items.length; i++) {
      let item: ExamTimeScoreStatistics = this.items[i];
      //this.doReScoringExamStudentsClick(item);
      await this.scoreExamTime(item, "" /*this.recipients*/);
    }
  }

  private loadingExportAll: boolean = false;

  private handleDropdownCommand(command: string) {
    switch (command) {
      case "exportAllExamTime": {
        this.doExportAllExamTimeClick();
        break;
      }
      case "downloadAllScore": {
        this.doDownloadAllScoreClick();
        break;
      }
    }
  }

  private async doDownloadAllScoreClick() {
    try {
      this.loadingExportAll = true;
      const examTimes: ExamTimeScoreStatistics[] = [];
      for (let i = 0; i < this.items.length; i++) {
        let item: ExamTimeScoreStatistics = this.items[i];
        examTimes.push(item);
      }
      let i: number = 0;
      let intervalId = setInterval(() => {
        try {
          if (i < examTimes.length) {
            this.handleDownloadExamStatisticsReportClick(examTimes[i]);
          } else {
            this.$message({
              message: `下载任务结束`,
              type: "info",
              offset: 60,
            });
            this.loadingExportAll = false;
            clearInterval(intervalId);
          }
        } finally {
          i++;
        }
      }, 300);
    } finally {
      //
    }
  }

  private async doExportAllExamTimeClick() {
    try {
      this.loadingExportAll = true;
      if (
        !this.query.examSchoolId &&
        !this.query.examProjectId &&
        !this.query.keyWords
      ) {
        this.$message({
          message: `查询条件不能为空。`,
          type: "warning",
          offset: 60,
        });
        return;
      }
      const tHeader = [
        "学校",
        "项目",
        "场次",
        "试卷",
        "日期",
        "实考人数",
        "平均分",
      ];
      const filterVal = [
        "examSchoolName",
        "examProjectName",
        "examTimeName",
        "examPaperName",
        "examStateTime",
        "examStudentCount",
        "avgScore",
      ];

      const tRow: any[] = [];
      const filename: string = "练习场次统计";
      //
      let curPage = 0;
      let totalPage = 0;
      do {
        curPage++;
        const { data } = await CommentApi.getExamTimesReport({
          pageSize: this.pageNum.pageSize,
          curPage: curPage,
          keyWords: this.query.keyWords,
          examSchoolId: this.query.examSchoolId,
          examProjectId: this.query.examProjectId,
          examType: this.examType,
          examStartTimeWithinDays: this.query.examStartTimeWithinDays,
        });
        curPage = data.curPage;
        totalPage = data.totalPage;
        tRow.push(...formatJson(filterVal, data.items));
        //
      } while (curPage < totalPage);
      //
      exportJson2Excel(tHeader, tRow, filename);
    } finally {
      this.loadingExportAll = false;
    }
  }

  doRouteToStudentsScoreListClick(row: ExamTimeScoreStatistics) {
    this.$router.push({
      path: "examStudentScoreList",
      query: {
        examProjectId: row.examProjectId,
        examTimeId: row.examTimeId,
        examSchoolId: row.examSchoolId,
        examType: "mockExamMode",
      },
    });
  }

  private loadingScore: boolean = false;
  async scoreExamTime(examTime: ExamTimeScoreStatistics, recipients: string) {
    try {
      this.loadingScore = true;
      //
      await ExamPrjApi.postScoreExamProject({
        examProjectId: examTime.examProjectId,
        //examSchoolId: examTime.examSchoolId,
        examTimeId: examTime.examTimeId,
        scoreExamAnswerPackages: true,
        scoreLocalExamAnswerPackageFiles: true,
        recipients: recipients,
      });
    } finally {
      this.loadingScore = false;
    } //try 0
  }

  async handleDownloadExamStatisticsReportClick(row: ExamTimeScoreStatistics) {
    try {
      const fileName = `${row.examProjectName}(${row.examSchoolName}-${row.examTimeName})考生成绩明细[(不含缺考)].xlsx`;
      const baseUrl: string = process.env.VUE_APP_BASE_API || "";
      const examStudentsReportShortFileUrl = `${baseUrl}${
        baseUrl.endsWith("/") ? "" : "/"
      }v1/examComment/statistics/download/examStudentsReport?examProjectId=${
        row.examProjectId
      }&examTimeId=${row.examTimeId}&fileName=${fileName}`;

      ResApi.download(`${examStudentsReportShortFileUrl}`, {}, fileName);
    } catch (error) {
      //
    }
  }
  private loadingScoreExamAnswerPackage: boolean = false;
  async doReScoringExamStudentsClick(row: ExamTimeScoreStatistics) {
    //参数判断
    if (row.examProjectId == "" || row.examTimeId == "") {
      this.$message({
        message: "请先选择及搜索：考试项目及场次。",
        type: "warning",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
      return;
    }

    await this.$confirm(`重评当前统计结果, 是否继续?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    });
    try {
      this.loadingScoreExamAnswerPackage = true;
      //2021/09/08测评不需要邮件与发邮件功能分离
      //await this.promptRecipients();
      //
      await this.scoreExamTime(row, "" /*this.recipients*/);

      this.$message({
        message: "重评已提交。",
        type: "success",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
    } finally {
      this.loadingScoreExamAnswerPackage = false;
    }
  }

  private recipients: string = "";
  async promptRecipients() {
    const promptData: any = await this.$prompt(
      "请输入接收测评结果的邮箱",
      "提示",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: this.recipients,
        inputPattern:
          /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: "邮箱格式不正确",
      }
    );
    this.recipients = promptData.value;
  }

  private loadingSendEmail: boolean = false;

  private async doSendExamStatisticsReportClick(row: ExamTimeScoreStatistics) {
    //参数判断
    if (row.examProjectId == "" || row.examSchoolId == "") {
      this.$message({
        message: "参数错误：项目、学校不能为空。",
        type: "warning",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
      return;
    }
    //
    await this.promptRecipients();
    //
    try {
      this.loadingSendEmail = true;
      await CommentApi.sendExamStatisticsReport({
        examProjectId: row.examProjectId,
        examSchoolId: row.examSchoolId,
        examTimeId: row.examTimeId,
        recipients: this.recipients,
      });
      this.$message({
        message: `邮件已发送至：${this.recipients}。`,
        type: "success",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
    } finally {
      this.loadingSendEmail = false;
    }
  }

  private getCascadeItem(keyId: string, cascades: any[]): any {
    let result = undefined;
    cascades.some(function iter(obj) {
      if (obj.keyId == keyId) {
        result = obj;
        return true;
      }
      return Array.isArray(obj.children) && obj.children.some(iter);
    });
    return result;
    /*
    for (let i = 0; i < cascades.length; i++) {
      if (cascades[i].keyId == keyId) {
        return cascades[i];
      }
      if (_.has(cascades[i], "children")) {
        return this.getCascadeItem(keyId, cascades[i].children);
      }
    }
    */
  }

  private getCascadeSchoolId(schoolId: string): string[] {
    let arr: string[] = [];
    let keyId: string = schoolId;
    do {
      const item = this.getCascadeItem(keyId, this.schools);
      if (item) {
        arr.push(keyId);
        keyId = item.parentKeyId;

        continue;
      }
      break;
    } while (true);
    return arr.reverse();
  }

  private removeNullChildren(cascades: any[]) {
    let i: number = 0;
    while (i < cascades.length) {
      switch (_.get(cascades[i], "dataType", "")) {
        case "school": {
          if (_.get(cascades[i], "children", []).length == 0) {
            delete cascades[i]["children"];
          } else {
            this.removeNullChildren(cascades[i].children);
          }
          i++;
          break;
        }
        case "area": {
          if (_.get(cascades[i], "children", []).length == 0) {
            cascades.splice(i, 1);
          } else {
            this.removeNullChildren(cascades[i].children);
            i++;
          }
          break;
        }
        default:
          i++;
      } //switch
    }
  }

  handleChangeCascadeExamSchool(examSchoolId: any) {
    this.__init_examProjectList();
  }

  private async __init_examProjectList() {
    try {
      this.examProjectList.splice(0, this.examProjectList.length);
      this.examTimeGroupList.splice(0, this.examTimeGroupList.length);
      const res = await getExamProjectList({
        examType: this.examType,
        pageSize: 999,
        curPage: 1,
        examStartTimeWithinDays: this.query.examStartTimeWithinDays,
        examSchoolId: this.query.examSchoolId || "",
      });

      this.examTimeGroupList = _.uniq(
        res.data.items.map((item: any) => item.examEndTimeStr)
      )
        .sort((a: any, b: any) => {
          if (a < b) {
            return 1;
          } else if (a > b) {
            return -1;
          }
          return 0;
        })
        .map((item: any) => {
          return {
            value: item,
            label: item,
          };
        });

      this.examProjectList = res.data.items.map((item: any) => {
        return _.merge(item, {
          value: item.examProjectId,
          label: item.examProjectName,
        });
      });
    } finally {
      const examProject = this.examProjectList.find((item) => {
        return item.examProjectId == this.query.examProjectId;
      });
      if (!examProject) {
        this.query.examProjectId = "";
      }
    }
  }

  private __init() {
    getSchoolCascadeList().then(({ data }) => {
      this.schools = data.items;
      this.removeNullChildren(this.schools);
    });
    this.__init_examProjectList();
  }

  private autoRefreshInterval: NodeJS.Timeout | undefined = undefined;
  mounted() {
    this.__init();
    //准备参数
    if (this.$route.query.examProjectId) {
      this.query.examProjectId = this.$route.query.examProjectId as string;
    }
    if (this.$route.query.examSchoolId) {
      this.query.examSchoolId = this.$route.query.examSchoolId as string;
    }
    if (parseInt(this.$route.query.curPage as string)) {
      this.pageNum.curPage = parseInt(this.$route.query.curPage as string);
    }
    if (parseInt(this.$route.query.pageSize as string)) {
      this.pageNum.pageSize = parseInt(this.$route.query.pageSize as string);
    }
    //
    this.getExamAutoScoreListData(true);
    clearInterval(this.autoRefreshInterval!);
    this.autoRefreshInterval = setInterval(() => {
      if (!this.loading && !this.loadingExportAll) {
        this.getExamAutoScoreListData(false);
      }
    }, 30 * 1000);
  }

  activated() {
    //准备参数
    if (this.$route.query.examProjectId) {
      this.query.examProjectId = this.$route.query.examProjectId as string;
    }
    if (this.$route.query.examSchoolId) {
      this.query.examSchoolId = this.$route.query.examSchoolId as string;
    }
    if (parseInt(this.$route.query.curPage as string)) {
      this.pageNum.curPage = parseInt(this.$route.query.curPage as string);
    }
    if (parseInt(this.$route.query.pageSize as string)) {
      this.pageNum.pageSize = parseInt(this.$route.query.pageSize as string);
    }
    //
    this.getExamAutoScoreListData(true);
    clearInterval(this.autoRefreshInterval!);
    this.autoRefreshInterval = setInterval(() => {
      this.getExamAutoScoreListData(false);
    }, 30 * 1000);
  }

  deactivated() {
    clearInterval(this.autoRefreshInterval!);
  }

  beforeRouteEnter(to: any, from: any, next: any) {
    try {
      const s1 = ((_.last(to.path.split("/")) as string) || "").toLowerCase();
      const s2 = ((_.last(from.path.split("/")) as string) || "").toLowerCase();
      console.log(
        `beforeRouteEnter, ${to.path} ~ ${s1} <--> ${from.path} ${s2}`
      );
      if (s1 == s2 && to.path != from.path) {
        console.log(`beforeRouteEnter, true, `);

        /*
        setTimeout(() => {
          this.getExamAutoScoreListData(true);
        }, 1000);
        */
      }
    } finally {
      next();
    }
  }

  beforeRouteUpdate(to: any, from: any, next: any) {
    try {
      const s1 = ((_.last(to.path.split("/")) as string) || "").toLowerCase();
      const s2 = ((_.last(from.path.split("/")) as string) || "").toLowerCase();
      console.log(`beforeRouteUpdate, ${s1}, ${s2}`);
      if (s1 == s2 && to.Path != from!.Path) {
      }
    } finally {
      next();
    }
  }

  beforeRouteLeave(to: any, from: any, next: any) {
    try {
      console.log(`beforeRouteLeave`);

      const s1 = ((_.last(to.path.split("/")) as string) || "").toLowerCase();
      const s2 = ((_.last(from.path.split("/")) as string) || "").toLowerCase();
      const that = this;
      if (s1 == s2) {
        //console.log(`beforeRouteLeave $destroy`);
        //that.$destroy();
      }
    } finally {
      next();
    }
  }
}
