






























import echarts, { EChartOption } from "echarts";
import { Component, Prop, Watch, Emit } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ResizeMixin from "@/components/Charts/mixins/resize";
import * as _ from "lodash";
import cardBox from "@/components/card-box/index.vue";
import StatisticsExamPaperSectionScoreSection from "./statistics-exam-paper-section-score-section.vue";
import * as rpt from "@/api/examProjectReport";
import { CommentParams, ScoreStatistics } from "@/classes/exam-comment";

interface ExamScoreSectionItem extends ScoreStatistics {
  frag1ScoreCount: number;
  frag2ScoreCount: number;
  frag3ScoreCount: number;
  frag4ScoreCount: number;
  frag5ScoreCount: number;
  frag6ScoreCount: number;
  frag7ScoreCount: number;
  frag8ScoreCount: number;
  frag9ScoreCount: number;
  frag10ScoreCount: number;
  frag11ScoreCount: number;
}

const animationDuration = 6000;

interface Serie {
  name: string;
  data: { name: string; value: number; dataKey?: string }[];
  itemStyle?: Object;
}

interface IBarChartData {
  series: Serie[];
}

@Component({
  name: "StatisticsExamScoreSection",
  components: {
    cardBox,
    StatisticsExamPaperSectionScoreSection,
  },
})
export default class extends mixins(ResizeMixin) {
  @Prop({ default: () => new CommentParams() }) private params!: CommentParams;
  @Emit("chartClick") handleClick(params: any) {}

  private chartWidth: string = "100%";
  private chartHeight: string = "300px";
  private chartClassName: string = "chart";
  private chartRef = "chart";
  private chartData: IBarChartData = {
    series: [],
  };

  private title: string = "各分数人数分布情况分析";

  private scoreSectionData: ExamScoreSectionItem[] = [];

  private async __init() {
    if (!this.params.examProjectId) {
      return;
    }
    const { data } = await rpt.getExamScoreSectionStatisticsReport(this.params);
    this.scoreSectionData = data.items;
    //
    this.chartData.series.splice(0, this.chartData.series.length);
    if (this.scoreSectionData.length == 1) {
      const item = this.scoreSectionData[0];
      const serie: Serie = {
        name: "各分数段人数分布",
        data: [],
      };
      serie.data.push({
        name: `[0,${item.standardScore * 0.1})`,
        value: item.frag1ScoreCount,
      });
      serie.data.push({
        name: `[${item.standardScore * 0.1},${item.standardScore * 0.2})`,
        value: item.frag2ScoreCount,
      });

      serie.data.push({
        name: `[${item.standardScore * 0.2},${item.standardScore * 0.3})`,
        value: item.frag3ScoreCount,
      });
      serie.data.push({
        name: `[${item.standardScore * 0.3},${item.standardScore * 0.4})`,
        value: item.frag4ScoreCount,
      });
      serie.data.push({
        name: `[${item.standardScore * 0.4},${item.standardScore * 0.5})`,
        value: item.frag5ScoreCount,
      });
      serie.data.push({
        name: `[${item.standardScore * 0.5},${item.standardScore * 0.6})`,
        value: item.frag6ScoreCount,
      });
      serie.data.push({
        name: `[${item.standardScore * 0.6},${item.standardScore * 0.7})`,
        value: item.frag7ScoreCount,
      });
      serie.data.push({
        name: `[${item.standardScore * 0.7},${item.standardScore * 0.8})`,
        value: item.frag8ScoreCount,
      });
      serie.data.push({
        name: `[${item.standardScore * 0.8},${item.standardScore * 0.9})`,
        value: item.frag9ScoreCount,
      });
      serie.data.push({
        name: `[${item.standardScore * 0.9},${item.standardScore * 1.0})`,
        value: item.frag10ScoreCount,
      });
      serie.data.push({
        name: `[${item.standardScore * 1.0}]`,
        value: item.frag11ScoreCount,
      });
      this.chartData.series.push(serie);
    }
    this.setOptions(this.chartData);
  }

  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
    this.__init();
  }

  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }

  private initChart() {
    this.$refs[this.chartRef];
    this.chart = echarts.init(
      /*this.$el*/ this.$refs[this.chartRef] as HTMLDivElement,
      "macarons"
    );
    this.setOptions(this.chartData);
    this.chart.on("chartClick", this.handleClick);
  }

  private setOptions(chartData: IBarChartData) {
    let xAxisData: string[] = [];
    let legendData: string[] = [];
    let series: any[] = [];
    for (let i = 0; i < chartData.series.length; i++) {
      let xData: string[] = chartData.series[i].data.map((item) => {
        return item.name;
      });
      legendData.push(chartData.series[i].name);
      xAxisData.push(...xData);
      series.push(
        _.merge(
          {
            name: "",
            type: "line",
            stack: "Total",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: [],
            animationDuration,
          },
          chartData.series[i]
        )
      );
    }
    xAxisData = _.uniq(xAxisData);
    //
    if (this.chart) {
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: 30,
          left: "2%",
          right: "2%",
          bottom: "3%",
          containLabel: true,
        },
        legend: {
          data: legendData, //["expected", "actual"],
        },
        xAxis: [
          {
            type: "category",
            data: xAxisData, //["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisLabel: {
              rotate: 30,
            },
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false,
            },
          },
        ],
        series: series,
        color: [
          "#5470c6",
          "#91cc75",
          "#fac858",
          "#ee6666",
          "#73c0de",
          "#3ba272",
          "#fc8452",
          "#9a60b4",
          "#ea7ccc",
        ],
      };
      this.chart!.setOption(option as EChartOption<EChartOption.SeriesBar>);
    }
  }
}
