










import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  name: "CardBox",
})
export default class extends Vue {
  @Prop() private title!: string;
  @Prop({ default: "never" }) private shadow?: string; //阴影:hover鼠标悬浮时显示;always总是显示;never不显示
}
