import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3c612c61&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&scoped=true&"
export * from "./index.vue?vue&type=script&lang=ts&scoped=true&"
import style0 from "./index.vue?vue&type=style&index=0&id=3c612c61&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c612c61",
  null
  
)

export default component.exports