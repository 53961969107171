import { ExamProject } from "./exam-project";
export class CommentParams {
    examSchoolId: string = "";
    examProjectId: string = "";
    examPaperId?: string;
    examClassName?: string;
    examPaperFileName?: string;
    __vueWatchTag?: number;
}

export interface ScoreStatistics {
    standardCount: number, //报考人数
    count: number, //实考人数
    standardScore: number, //总分
    score: number,//得分
    avgScore: number,//平均分
    overAvgScoreCount: number,//超过平均分人数
    overAvgScoreRate: number,//超均率
    highestScore: number,//最高分
    highestScoreCount: number,//最高分人数
    lowestScore: number,//最低分
    lowestScoreCount: number,//最低分人数
    fullScoreCount: number,//满分人数
    fullScoreRate: number,//满分率
    excellentScoreCount: number,//优秀人数
    excellentRate: number,//优秀率
    goodScoreCount: number,//优良人数
    goodScoreRate: number,//优良率
    passingScoreCount: number,//及格人数
    passingRate: number,//及格率
    lowScoreCount: number,//低分人数
    lowScoreRate: number,//低分率
    zeroScoreCount: number,//0分人数
    zeroScoreRate: number,//0分率
    scoringRate: number,//得分率
    standardDeviation: number,//标准差
    distinction: number,//区分度
    difficulty: number,//难度
    reliability: number,//信度*
    validity: number,//效度*
}

/**
 * 项目统计分析,对应Request返回的data
 */
export interface ExamProjectStatisticsReport extends ScoreStatistics {
    examProjectId: string,
    examProjectName: string,
    registeredCount: number,//报考人数
    realExamCount: number, //实考人数
}

export interface ExamQuestionScoreStatistics extends ScoreStatistics {
    questionId: string,
    questionNo: string,
    questionName: string,
}

/**
 * 试题列表统计分析,对应Request返回的data
 */
export interface ExamQuestionsStatisticsReport {
    items: ExamQuestionScoreStatistics[]
}

export interface ExamStudentScoreStatistics extends ScoreStatistics {
    examStudentId: string,
    examStudentName: string,
    examAccount: string,
    className: string,
    examSchoolId: string,
    examSchoolName: string,
    schoolId: string,
    schoolName: string,
    examTimeId: string,
    examTimeName: string,
    examQuestions: ExamQuestionScoreStatistics[],//每一题的得分
}

/**
 * 考生列表统计分析,对应Request返回的data
 */
export interface ExamStudentsStatisticsReport {
    items: ExamStudentScoreStatistics[]
}


export class CommentExamPaper {
    examPaperId: string = "";
    examPaperName: string = "";
    examPaperFileName: string = "";
    examPaperFileUrl: string = "";
    fileHashCode: string = "";
}

export class CommentExamClass {
    className: string = "";
    studentCount: number = 0
}

export interface CommentExamProject extends ExamProject {
    examStudentCount: number,
    examPapers: CommentExamPaper[],
    examClasses: CommentExamClass[]
}


export interface ExamTimeScoreStatistics extends ScoreStatistics {
    examProjectId: string,
    examProjectName: string,
    examRoomId: string,
    examRoomName: string,
    examSchoolId: string,
    examSchoolName: string,
    examPaperName: string,
    examState: string,
    examStateText: string,
    examStateTime: Date,
    examStudentCount: number,
    examTimeId: string,
    examTimeName: string,
    examEndedCount: number,

    subjectiveScoreSuccessCount: number,
    subjectiveScoreFailedCount: number,
    subjectiveScoreNullCount: number,

    objectiveScoreSuccessCount: number,
    objectiveScoreFailedCount: number,
    objectiveScoreNullCount: number,

    scoreFailedCount: number,
    scoreNullCount: number,
    scoreSuccessCount: number,
}

export interface ExamTimesStatisticsReport {
    items: ExamTimeScoreStatistics[]
}

export interface ExamSchoolStatistics extends ScoreStatistics {
    examProjectName: string,
    examSchoolName: string,
    examTimeCount: number,
    examStudentCount: number,
    minExamAccount: string,
    maxExamAccount: string,
    minSchoolName: string,
    maxSchoolName: string,
    multiSchool: boolean,
    examNull: number,
    examNone: number,
    examHas: number,
    examEnded: number,
    pkgCount: number,
    firstExamStateTime: Date | null,
    lastExamStateTime: Date | null,
    examProjectId: string,
    examSchoolId: string,
    examStudents: []
}

export interface ExamSchoolsStatisticsReport {
    items: ExamSchoolStatistics[]
}

